.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.flex-container {
	margin-top: 15px;
	display: flex;
	flex-wrap: wrap;
}

.flex-container h3 {
	font-size: min(3vw, 20px);
	text-align: center;
}

.blackBar {
	width: 100%;
	height: 0.08em;
	background-color: gray;
	margin-top: 20px;
	margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
	.w3-center-small {
		float: none !important;
	}

	.banner {
		font-size: 3vw !important;
	}
}
@media screen and (min-width: 993px) {
	.banner {
		font-size: 14px !important;
	}
}

#measurements {
	font-size: (1.4vw, 15px) !important;
}

button {
	font-size: 0.9rem;
	font-weight: 700;
	border: none;
	border-radius: 50px;
	padding: 0.3rem 1rem;
	margin-left: 0.5rem;
}

.button-default {
	background: #247ba0;
	color: #fff;
}

.fa {
	padding: 20px;
	font-size: 30px;
	text-align: center;
	text-decoration: none;
	margin: 5px 2px;
}

.fa-instagram {
	border-radius: 20%;
	background: #a7cde8;
	color: black;
}

/* .modal-img {
  height: calc(100% - 33px);
  max-width:30%; 
  opacity: 1 !important;
} */
.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
	margin: auto;
	display: block;
	/* width: 80%; */
	height: calc(100% - 100px);
	max-width: 700px;
}

/* Caption of Modal Image */
#caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0);
	}
	to {
		-webkit-transform: scale(1);
	}
}

@keyframes zoom {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

/* The Close Button */
.close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.close:hover,
.close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		object-fit: cover;
		width: 80%;
		height: auto;
	}
}

.banner {
	font-size: 1.4vw;
	position: absolute;
	bottom: 0px;
	width: 100%;
}

.artItemContainer {
	position: relative;
}

.artMetaDataContainer {
	h3,
	h6 {
		margin-bottom: 0px;
		margin-top: 0px;
	}
	margin-top: 10px;
	margin-bottom: 20px;
}
